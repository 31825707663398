import BaseModel from '@/api/models/BaseModel';
import { PurchasedThemeType } from '../types/latestPurchaseList.type';

class LatestPurchaseListResponseModel extends BaseModel {
  public items: PurchasedThemeType[];

  constructor(arg: any) {
    super(arg);
    this.items = arg.items;
  }
}
export default LatestPurchaseListResponseModel;
