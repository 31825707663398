import httpClient from '@/api/httpClient';
import ErrorModel from '@/api/models/ErrorModel';
import PartnerThemesListResponseModel from '@/api/models/PartnerThemesListResponseModel';

const ENDPOINT_URL = '/pd/themes/list';

export const partnerThemesList = (): Promise<PartnerThemesListResponseModel | ErrorModel> => {
  return httpClient
    .get(ENDPOINT_URL)
    .then((response) => new PartnerThemesListResponseModel(response))
    .catch((error) => error);
};
