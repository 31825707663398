import BaseModel from './BaseModel';

class PartnerThemesListResponseModel extends BaseModel {
  public themes: { id: number; uuid: string; name: string }[];

  constructor(arg: any) {
    super(arg);
    this.themes = arg.themes;
  }
}

export default PartnerThemesListResponseModel;
