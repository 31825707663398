import Vue from 'vue';
import { defineComponent, ref, reactive, watch } from '@vue/composition-api';
import { ZidIcon, ZidLoader, ZidTable, ZidTableRowGroup, ZidTableHeader, ZidTableRow, ZidTableCell } from '@zidsa/ui';
import BaseText from '@/components/text/BaseText.vue';
import { ThemesPurchaseListType } from './types/latestPurchaseList.type';
import ErrorModel from '@/api/models/ErrorModel';
import { getLatestPurchaseList } from './api/latest-purchase-list';

export default defineComponent({
  components: {
    BaseText,
    ZidIcon,
    ZidLoader,
    ZidTable,
    ZidTableRowGroup,
    ZidTableHeader,
    ZidTableRow,
    ZidTableCell,
  },
  props: {
    themeId: {
      type: String,
      required: true,
      default: '',
    },
  },
  setup(props) {
    const loadingLatestPurchaseList = ref(true);
    const latestPurchaseList = reactive<ThemesPurchaseListType>({
      list: [],
    });

    const getLatestThemesPurchaseListFromApi = async (theme_id: string) => {
      latestPurchaseList.list = [];
      loadingLatestPurchaseList.value = true;
      const response = await getLatestPurchaseList(theme_id);
      if (response instanceof ErrorModel) {
        Vue.$toast.error('Data not found for purchases');
        loadingLatestPurchaseList.value = false;
        return;
      }
      latestPurchaseList.list = [...response.items];
      loadingLatestPurchaseList.value = false;
    };

    watch(
      () => props.themeId,
      async (newStatisticsState) => {
        await getLatestThemesPurchaseListFromApi(newStatisticsState);
      },
      { deep: true },
    );

    return {
      latestPurchaseList,
      loadingLatestPurchaseList,
    };
  },
});
