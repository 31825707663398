import httpClient from '@/api/httpClient';
import ErrorModel from '@/api/models/ErrorModel';
import LatestPurchaseListResponseModel from '../models/LatestPurchaseListResponseModel';
import { PurchasesFilterParamsType } from '../types/latestPurchaseList.type';

const ENDPOINT_URL = '/market/themes/purchases';

export const getLatestPurchaseList = (theme_id: string): Promise<LatestPurchaseListResponseModel | ErrorModel> => {
  const filterParams: PurchasesFilterParamsType = {
    theme_id: theme_id,
    pageSize: '10',
  };

  return httpClient
    .get(`${ENDPOINT_URL}`, {
      params: filterParams,
    })
    .then((response) => new LatestPurchaseListResponseModel(response))
    .catch((error) => error);
};
