import BaseModel from '@/api/models/BaseModel';

class ActivationAnalyticsResponseModel extends BaseModel {
  public activations: {
    enabled: number;
    enabled_percentage: number;
    disabled: number;
    disabled_percentage: number;
  };

  constructor(arg: any) {
    super(arg);
    this.activations = arg.activations;
  }
}

export default ActivationAnalyticsResponseModel;
