import dayjs from 'dayjs';
import ErrorModel from '@/api/models/ErrorModel';
import { getAnalyticsOverviewData } from '@/domains/themes/api/dashboard/analyticsOverview';
import {
  StatisticsControlStateType,
  AnalyticsOverviewReturnType,
} from '@/domains/themes/types/dashboard/dashboard.type';

const useAnalyticsOverview = async (
  statisticsControlState: StatisticsControlStateType,
): Promise<AnalyticsOverviewReturnType> => {
  let localNextPayout = 0;
  let localTotalIncome = 0;
  let localTotalActivations = 0;
  let localTotalPreviews = 0;
  const startDate = statisticsControlState.startDate;
  const endDate = statisticsControlState.endDate;

  const formattedStartDate = dayjs(startDate).format('YYYY-MM-DD');
  const formattedendDate = dayjs(endDate).format('YYYY-MM-DD');
  const selectedThemeId = statisticsControlState.selectedTheme.value;
  const response = await getAnalyticsOverviewData(selectedThemeId, formattedStartDate, formattedendDate);
  if (response instanceof ErrorModel) {
    return {
      localNextPayout,
      localTotalIncome,
      localTotalActivations,
      localTotalPreviews,
    };
  }
  localNextPayout = Number(response.nextPayout) ?? 0;
  localTotalIncome = Number(response.income) ?? 0;
  localTotalActivations = Number(response.activations) ?? 0;
  localTotalPreviews = Number(response.previews) ?? 0;

  return {
    localNextPayout,
    localTotalIncome,
    localTotalActivations,
    localTotalPreviews,
  };
};

export default useAnalyticsOverview;
