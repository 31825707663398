import { reactive, ref } from '@vue/composition-api';
import { ChartDataSetType } from '@/domains/themes/types/dashboard/dashboard.type';

const useTotalPurchasesChart = (monthsList: string[] = [], updatedDatasets: ChartDataSetType[] = []) => {
  const totalPurchasesChart = reactive({
    data: {
      labels: [''],
      datasets: [{}],
    },
  });
  const totalPurchasesChartOptions = reactive({
    data: {
      responsive: false,
      maintainAspectRatio: false,
      scales: {
        xAxes: [{}],
        yAxes: [{}],
      },
      legend: {},
      tooltips: {},
    },
  });
  const purchaseChartDataLoaded = ref(false);

  totalPurchasesChart.data = {
    labels: monthsList,
    datasets: updatedDatasets,
  };

  totalPurchasesChartOptions.data = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      xAxes: [
        {
          gridLines: {
            display: false,
            color: 'black',
            zeroLineColor: '#fff',
            zeroLineWidth: 0,
          },
          stacked: true,
        },
      ],
      yAxes: [
        {
          stacked: true,
          ticks: {
            beginAtZero: true,
          },
        },
      ],
    },
    legend: {
      position: 'bottom',
      labels: {
        fontSize: 14,
        padding: 24,
        fontColor: '#8A94A6',
      },
    },
    tooltips: {
      callbacks: {
        label: function (tooltipItem: any, data: any) {
          const dataset = data.datasets[tooltipItem.datasetIndex];
          const currentValue = dataset.data[tooltipItem.index];
          return `${currentValue} ${currentValue === 1 ? 'Purchase' : 'Purchases'}`;
        },
      },
    },
  };

  purchaseChartDataLoaded.value = true;

  return {
    totalPurchasesChart,
    totalPurchasesChartOptions,
    purchaseChartDataLoaded,
  };
};

export default useTotalPurchasesChart;
