import BaseModel from '@/api/models/BaseModel';

class PurchaseChartResponseModel extends BaseModel {
  public purchases: Record<string, number>;

  constructor(arg: any) {
    super(arg);
    this.purchases = arg.purchases;
  }
}

export default PurchaseChartResponseModel;
