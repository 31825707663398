import Vue from 'vue';
import store from '@/store';
import dayjs from 'dayjs';
import { defineComponent, reactive, ref, onBeforeMount, onMounted, watch } from '@vue/composition-api';
import DashboardHeader from './dashboard-header/DashboardHeader.vue';
import LatestPurchaseList from './latest-purchase-list/LatestPurchaseList.vue';
import StatisticsContainer from './statistics/StatisticsContainer.vue';
import BaseText from '@/components/text/BaseText.vue';
import BaseIcon from '@/components/base-icon/BaseIcon.vue';
import { ZidSelect, ZidSelectHeader, ZidSelectBody, ZidSelectOption, ZidDatepicker, ZidIcon } from '@zidsa/ui';
import { partnerThemesList } from '@/api/top-level-apis/themes/partnerThemesList';
import { welcomePartner } from '@/api/top-level-apis/welcome-partner/welcomePartner';
import { createNamespacedHelpers } from 'vuex-composition-helpers';
import { InputProperty, DateRangeProperties } from '../../types/dashboard/dashboard.type';
import useAnalyticsOverview from '../../helpers/dashboard/useAnalyticsOverview';
import ErrorModel from '@/api/models/ErrorModel';

const { useActions } = createNamespacedHelpers('authentication');

export default defineComponent({
  components: {
    DashboardHeader,
    LatestPurchaseList,
    StatisticsContainer,
    BaseIcon,
    ZidIcon,
    ZidSelect,
    ZidSelectHeader,
    ZidSelectBody,
    ZidSelectOption,
    BaseText,
    ZidDatepicker,
  },
  setup() {
    const { setAccountCreationStepsState } = useActions(['setAccountCreationStepsState']);
    const nextPayout = ref(-1);
    const totalIncome = ref(-1);
    const totalActivations = ref(-1);
    const totalPreviews = ref(-1);
    const loadingThemesList = ref(false);
    const isThemesListDisabled = ref(false);
    const themesList = reactive<Record<string, { label: string; value: string }[]>>({
      item: [],
    });

    const statisticsControlState = reactive({
      selectedTheme: {
        label: '',
        value: '',
      },
      startDate: dayjs().subtract(1, 'month').format('YYYY-MM-DD'),
      endDate: dayjs().format('YYYY-MM-DD'),
    });

    const onDataChange = (changedValue: Record<string, string>, property: InputProperty.SelectedTheme) => {
      statisticsControlState[property] = JSON.parse(JSON.stringify(changedValue));
    };

    const onDatepickerChange = (changedValue: Record<string, string>, dataProperty: DateRangeProperties) => {
      statisticsControlState[dataProperty] = changedValue.value;
    };

    watch(
      statisticsControlState,
      async (newStatisticsControlState) => {
        if (dayjs(newStatisticsControlState.startDate).isAfter(dayjs(newStatisticsControlState.endDate))) {
          Vue.$toast.error('Date range is not valid');
          return;
        }
        nextPayout.value = -1;
        totalIncome.value = -1;
        totalActivations.value = -1;
        totalPreviews.value = -1;
        const { localNextPayout, localTotalIncome, localTotalActivations, localTotalPreviews } =
          await useAnalyticsOverview(newStatisticsControlState);
        nextPayout.value = localNextPayout;
        totalIncome.value = localTotalIncome;
        totalActivations.value = localTotalActivations;
        totalPreviews.value = localTotalPreviews;
      },
      { deep: true },
    );

    onBeforeMount(async () => {
      loadingThemesList.value = true;
      const response = await partnerThemesList();
      if (response instanceof ErrorModel || !response.themes) {
        isThemesListDisabled.value = true;
        loadingThemesList.value = false;
        Vue.$toast.error('Themes not found');
        return;
      }
      const themes = response.themes;
      themes.forEach((theme: { uuid: string; name: string }) => {
        themesList.item.push({
          label: theme.name,
          value: theme.uuid,
        });
      });
      statisticsControlState.selectedTheme = themesList.item.length ? themesList.item[0] : { label: '', value: '' };
      isThemesListDisabled.value = false;
      loadingThemesList.value = false;

      const welcomeResponse = await welcomePartner();
      if (!(welcomeResponse instanceof ErrorModel) && welcomeResponse.payload) {
        await setAccountCreationStepsState(welcomeResponse.payload.partner_email);
      }
    });

    onMounted(async () => {
      await store.dispatch('setIsAppLoading', false);
    });

    return {
      nextPayout,
      InputProperty,
      DateRangeProperties,
      statisticsControlState,
      totalIncome,
      totalActivations,
      totalPreviews,
      themesList,
      loadingThemesList,
      isThemesListDisabled,
      onDataChange,
      onDatepickerChange,
    };
  },
});
