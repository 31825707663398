import BaseModel from '@/api/models/BaseModel';

class AnalyticsOverviewResponseModel extends BaseModel {
  public nextPayout: number;
  public income: number;
  public previews: number;
  public activations: number;

  constructor(arg: any) {
    super(arg);
    this.nextPayout = arg.nextPayout;
    this.income = arg.income;
    this.previews = arg.previews;
    this.activations = arg.activations;
  }
}

export default AnalyticsOverviewResponseModel;
