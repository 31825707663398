import { reactive, ref } from '@vue/composition-api';

const useActivationChart = (
  themeId: string,
  activationsData: number[] = [-1, -1],
  activationsPercentages: number[] = [50, 50],
) => {
  const activationData = reactive({
    data: {
      labels: [''],
      datasets: [{}],
    },
  });

  const activationOptions = reactive({
    data: {
      responsive: false,
      maintainAspectRatio: false,
      cutoutPercentage: 0,
      legend: {},
      tooltips: {},
    },
  });

  const activationDataLoaded = ref(false);

  activationData.data = {
    labels: ['Enable', 'Disable'],
    datasets: [
      {
        label: themeId,
        data: activationsData,
        backgroundColor: ['#70DEBB', '#FDE264'],
      },
    ],
  };

  activationOptions.data = {
    responsive: true,
    maintainAspectRatio: false,
    cutoutPercentage: 75,
    legend: {
      position: 'bottom',
      align: 'start',
      labels: {
        fontSize: 14,
        padding: 24,
        fontColor: '#8A94A6',
      },
    },
    tooltips: {
      callbacks: {
        label: function (tooltipItem: any, data: any) {
          const dataset = data.datasets[tooltipItem.datasetIndex];
          const currentValue = dataset.data[tooltipItem.index];
          return ` ${currentValue !== -1 ? currentValue : 0} ${currentValue === 1 ? 'Activation' : 'Activations'}`;
        },
      },
    },
  };

  return {
    activationData,
    activationOptions,
    activationDataLoaded,
  };
};

export default useActivationChart;
