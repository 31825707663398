export type ChartDataSetType = {
  label: string;
  data: number[];
  backgroundColor: string;
};

export enum DateRangeProperties {
  StartDate = 'startDate',
  EndDate = 'endDate',
}

export enum InputProperty {
  SelectedTheme = 'selectedTheme',
}

export type StatisticsControlStateType = {
  selectedTheme: {
    label: string;
    value: string;
  };
  startDate: string;
  endDate: string;
};

export type AnalyticsOverviewReturnType = {
  localNextPayout: number;
  localTotalIncome: number;
  localTotalActivations: number;
  localTotalPreviews: number;
};

export type AnalyticsOverviewFilterParamsType = {
  startDate: string;
  endDate: string;
};

export interface ThemesAnalyticsFilterParamsInterface {
  theme_id?: string;
  dateFrom?: string;
  dateTo?: string;
}

export interface PurchaseChartApiResponseInterface {
  purchases: Record<string, number>;
}
