import httpClient from '@/api/httpClient';
import ErrorModel from '@/api/models/ErrorModel';
import { ThemesAnalyticsFilterParamsInterface } from '@/domains/themes/types/dashboard/dashboard.type';
import PurchaseChartResponseModel from '@/domains/themes/models/dashboard/PurchaseChartResponseModel';

const ENDPOINT_URL = '/market/themes/dashboard/purchase';

export const purchaseChart = (
  themeId: string,
  startDate: string,
  endDate: string,
): Promise<PurchaseChartResponseModel | ErrorModel> => {
  const filterParams: ThemesAnalyticsFilterParamsInterface = {};

  if (themeId) {
    filterParams.theme_id = themeId;
  }
  if (startDate) {
    filterParams.dateFrom = startDate;
  }
  if (endDate) {
    filterParams.dateTo = endDate;
  }

  return httpClient
    .get(ENDPOINT_URL, {
      params: filterParams,
    })
    .then((response) => new PurchaseChartResponseModel(response))
    .catch((error) => error);
};
