import Vue from 'vue';
import { defineComponent, reactive, ref, watch } from '@vue/composition-api';
import dayjs from 'dayjs';
import BaseText from '@/components/text/BaseText.vue';
import { ZidLoader } from '@zidsa/ui';
import { ZidIcon } from '@zidsa/ui';
import BaseIcon from '@/components/base-icon/BaseIcon.vue';
import PurchaseChart from './purchase-chart/PurchaseChart.vue';
import useActivationChart from '@/domains/themes/helpers/dashboard/useActivationChart';
import ActivationChart from './activation-chart/ActivationChart.vue';
import { getActivationAnalytics } from '@/domains/themes/api/dashboard/activationsAnalytics';
import { ChartDataSetType } from '@/domains/themes/types/dashboard/dashboard.type';
import useTotalPurchasesChart from '@/domains/themes/helpers/dashboard/useTotalPurchasesChart';
import { purchaseChart } from '@/domains/themes/api/dashboard/purchaseChart';
import ErrorModel from '@/api/models/ErrorModel';

export default defineComponent({
  props: {
    statisticsControlState: {
      type: Object,
      required: true,
      default: {},
    },
    totalIncome: {
      type: Number,
      required: true,
      default: -1,
    },
    totalPreviews: {
      type: Number,
      required: true,
      default: -1,
    },
    totalActivations: {
      type: Number,
      required: true,
      default: -1,
    },
  },
  components: {
    ZidIcon,
    BaseText,
    BaseIcon,
    ZidLoader,
    PurchaseChart,
    ActivationChart,
  },
  setup(props) {
    const totalPurchasesOfThemes = ref(0);
    const { activationData, activationOptions, activationDataLoaded } = useActivationChart('');
    const { totalPurchasesChart, totalPurchasesChartOptions, purchaseChartDataLoaded } = useTotalPurchasesChart();
    const PurchasesChartData = reactive<ChartDataSetType>({
      label: 'Total Purchases',
      backgroundColor: '#9081F6',
      data: [],
    });

    watch(
      () => props.statisticsControlState,
      async (newStatisticsState) => {
        const themeId = newStatisticsState.selectedTheme.value;
        const startDate = dayjs(newStatisticsState.startDate).format('YYYY-MM-DD');
        const endDate = dayjs(newStatisticsState.endDate).format('YYYY-MM-DD');
        const areDatesValid = dayjs(startDate).isAfter(dayjs(endDate));

        if (startDate && endDate) {
          if (areDatesValid) {
            Vue.$toast.error('Date range is not valid');
            return;
          }

          try {
            // Make two API calls in parallel
            const [activationResponse, purchaseResponse] = await Promise.all([
              getActivationAnalytics(themeId, startDate, endDate),
              purchaseChart(themeId, startDate, endDate),
            ]);

            // Process activation data
            if (activationResponse instanceof ErrorModel || !activationResponse.activations) {
              Vue.$toast.error('Failed to get activations analytics');
              return;
            }
            const { activations } = activationResponse;
            let themeActivationData = [activations.enabled, activations.disabled];
            let themeActivationPercentages = [activations.enabled_percentage, activations.disabled_percentage];
            themeActivationData = themeActivationData.every((data) => data === 0) ? [-1, -1] : themeActivationData;
            themeActivationPercentages = themeActivationPercentages.every((data) => data === 0)
              ? [50, 50]
              : themeActivationPercentages;
            const activationCharNewValues = useActivationChart(
              themeId,
              themeActivationData,
              themeActivationPercentages,
            );
            activationData.data = activationCharNewValues.activationData.data;
            activationOptions.data = activationCharNewValues.activationOptions.data;
            activationDataLoaded.value = true;

            // Process purchase data
            if (purchaseResponse instanceof ErrorModel || !purchaseResponse.purchases) {
              Vue.$toast.error('Failed to get Purchase charts data');
              return;
            }

            const purchases = purchaseResponse.purchases;
            const purchaseskeys = Object.keys(purchases);
            totalPurchasesOfThemes.value = Object.values(purchases).reduce((key, value) => key + value, 0);
            PurchasesChartData.data = [];
            Object.entries(purchases).forEach(([month, orderData]) => {
              const data = orderData;
              PurchasesChartData.data.push(Number(data));
            });
            const orderChartNewValues = useTotalPurchasesChart(purchaseskeys, [PurchasesChartData]);
            totalPurchasesChart.data = orderChartNewValues.totalPurchasesChart.data;
            totalPurchasesChartOptions.data = orderChartNewValues.totalPurchasesChartOptions.data;
          } catch (error) {
            // Handle errors
            Vue.$toast.error('An error occurred while fetching charts data');
          }
        }
      },
      { deep: true },
    );

    return {
      activationData,
      activationOptions,
      totalPurchasesChart,
      activationDataLoaded,
      totalPurchasesOfThemes,
      purchaseChartDataLoaded,
      totalPurchasesChartOptions,
    };
  },
});
